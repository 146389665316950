import icon_pay_success from '~/assets/image/icon/icon_pay_success.png'
import icon_pay_fail from '~/assets/image/icon/icon_pay_fail.png'
import icon_pay_pending from '~/assets/image/icon/icon_pay_pending.png'
// orderStatus 订单状态 1=待付款 3=成功 4=失败
export enum ORDER_STATUS {
  all = 0,
  pending = 1,
  success = 3,
  fail = 4
}

// 扩展 ORDER_STATUS 枚举的方法
export function fakeIndex(status: ORDER_STATUS): number {
  switch (status) {
    case ORDER_STATUS.all:
      return 0
    case ORDER_STATUS.pending:
      return 1
    case ORDER_STATUS.success:
      return 3
    case ORDER_STATUS.fail:
      return 4
  }
}

export function label(status: ORDER_STATUS): string {
  switch (status) {
    case ORDER_STATUS.all:
      return '全部状态' // 这里的 .tr 可能是 GetX 中的翻译方法，根据实际情况处理
    case ORDER_STATUS.pending:
      return '待支付'
    case ORDER_STATUS.success:
      return '成功'
    case ORDER_STATUS.fail:
      return '失败'
  }
}

export function icon(status: ORDER_STATUS): string {
  switch (status) {
    case ORDER_STATUS.all:
      return ''
    case ORDER_STATUS.pending:
      return icon_pay_pending
    case ORDER_STATUS.success:
      return icon_pay_success
    case ORDER_STATUS.fail:
      return icon_pay_fail
  }
}
